/* global MatgenGlobal, $, dataLayer */

import { authHeaderOpts, createThumbnail } from '../../+auth-global/data';
import {
  mainContentLoader,
  mainContentLoaderStop,
} from '../../+auth-global/display';
import { formatTimer } from '../../website-data-page/website-data/data';
import { filePreview } from './display';
import { v4 as UUID } from 'uuid';

const gaOptionEvents = () => {
  const objs = MatgenGlobal.editor.cur().fabric.getObjects();
  const gaEvents = [];
  for (let i = 0; i < objs.length; i++) {
    if (objs[i].componentId) {
      let param2 = objs[i].altText ? objs[i].altText : objs[i].text;

      if (objs[i].type === 'group') {
        param2 = '';
        for (let j = 0; j < objs[i].length; j++) {
          param2 += objs[i]._objects(j).altText
            ? objs[i]._objects(j).altText
            : objs[i]._objects(j).text;
        }
      }

      const gEvent = {
        param1: objs[i].name,
        param2,
        event: 'option_selected',
      };
      console.log('gEvent', gEvent);
      gaEvents.push(gEvent);
    }
  }
  return gaEvents;
};

export const pageGAEvents = async () => {
  //const template = await MatgenGlobal.Data.getTemplate(template_id);
  const events = gaOptionEvents();
  sessionStorage.setItem('gaPageEvents', JSON.stringify(events));
  //console.error('PAGE GA EVENTS:', MatgenGlobal.gaPageEvents);
};

export const materialGAEvents = async ({ template_id } = {}) => {
  const qid = '9e54f878-ba02-452a-b607-8d513cd0a286';

  if (!MatgenGlobal.questionnaire) {
    MatgenGlobal.questionnaire = await MatgenGlobal.Data.getQuestionnaire(qid);
    //console.log('QUESTIONNAIRE LOADED', MatgenGlobal.questionnaire);
  }

  const template = await MatgenGlobal.Data.getTemplate(template_id);
  //console.error('GA EVENTS:', template);
  //const tags = await MatgenGlobal.Data.getTemplateTags(template_id);
  //const tagAnswerIds = tags.map((t) => t.answer_id);
  const dist = MatgenGlobal.translatedTags.find(
    (t) => t.type === 'distribution'
  );
  //const distributionQuestion = dist.q;
  const distributionAnswer = dist.a;

  const goal = MatgenGlobal.translatedTags.find((t) => t.type === 'goal');
  //const goalQuestion = goal.q;
  const goalAnswer = goal.a;

  const demo = MatgenGlobal.translatedTags.filter(
    (t) => t.type === 'demographics'
  );

  const timerStart = sessionStorage.getItem('editorTimer');

  const elapsedTime = formatTimer(Date.now() - timerStart);
  sessionStorage.removeItem('editorTimer');

  dataLayer.push({
    param1: goalAnswer,
    param2: distributionAnswer,
    param3: demo[0].a,
    param4: demo[1].a,
    param5: template.name,
    event: 'matgen-finalize',
  });

  dataLayer.push({
    param1: elapsedTime,
    event: 'matgen-timer',
  });

  let oEvents = sessionStorage.getItem('gaPageEvents');
  if (oEvents) {
    oEvents = JSON.parse(oEvents);
    for (let i = 0; i < oEvents.length; i++) {
      dataLayer.push(oEvents[i]);
    }
    sessionStorage.removeItem('gaPageEvents');
  }
  if (MatgenGlobal.editor != null) {
    oEvents = gaOptionEvents();
    for (let i = 0; i < oEvents.length; i++) {
      dataLayer.push(oEvents[i]);
    }
  }
};

export const saveMaterialPage = async ({
  id,
  //study_id,
  //template_id,
  cb = false,
} = {}) => {
  mainContentLoader({ showMessage: true });
  $('#loader-message').text('Saving material page...');
  //const objects = MatgenGlobal.editor.cur().fabric.getObjects();
  MatgenGlobal.pages.sort((a, b) => a.number - b.number);
  //console.error('PAGES:', MatgenGlobal.pages, MatgenGlobal.page);
  /*for (let i = 0; i < objects.length; i++) {
    if (objects[i].studyDataOverride) {

    }
  }*/
  /*for (let i = 0; i < objects.length; i++) {
    try {
      if (objects[i].studyDataUpdate) {
        console.log(objects[i].studyDataConnection);
        promises.push(
          MatgenGlobal.Data.saveStudyData(
            {
              study_id: MatgenGlobal.currentStudy.id,
              question_id: objects[i].studyDataConnection,
              answer: JSON.stringify({
                answer:
                  objects[i].type === 'textbox'
                    ? objects[i].text
                    : objects[i].src,
                override: true,
              }),
            },
            true
          )
        );
        delete objects[i].studyDataUpdate;
      }
    } catch (e) {
      console.error(e);
    }
  }*/
  //await Promise.all(promises);
  MatgenGlobal.UI.loading('Saving material page...');
  const pageObj = JSON.parse(MatgenGlobal.editor.cur().getJSON());
  const page_file_response = await MatgenGlobal.Data.saveMaterialPageFile(
    id,
    MatgenGlobal.pages[MatgenGlobal.page].id,
    pageObj
  );
  console.log(page_file_response);
  $('#matgen-loader').attr('message', 'Saving preview image...');
  const preview_response = await MatgenGlobal.UI.savePagePreview(
    id,
    MatgenGlobal.tenant_id
  );

  console.log(preview_response);

  await createThumbnail(
    MatgenGlobal.pages[MatgenGlobal.page].id,
    id,
    'material'
  );

  if (cb && typeof cb === 'function') {
    cb();
  } else {
    mainContentLoaderStop();
  }
  //MatgenGlobal.UI.stopLoading();
};

export const saveMaterialRecord = async ({
  study_id,
  name,
  id,
  template_id,
  cb,
  cont,
  //thumb = true,
} = {}) => {
  mainContentLoader({ showMessage: true });
  $('#loader-message').text('Saving material...');
  let error = false;
  try {
    const user = await MatgenGlobal.Amplify.Auth.currentUserCredentials();
    const response = await MatgenGlobal.Data.API.request('/materials', 'POST', {
      id,
      template_id,
      user_name: user.identityId,
      name,
      user_folder_id: study_id,
      tags: JSON.stringify(MatgenGlobal.answerTags),
    });

    /*if (thumb) {
      const pages = await MatgenGlobal.Data.getPages(template_id);
      pages.sort((a, b) => a.number - b.number);
      await createThumbnail(pages[0].id, id, 'material');
    }*/

    //mainContentLoaderStop();
    if (cb && typeof cb === 'function') {
      cb({ response, name, cont, id });
    }

    //console.log(response);
  } catch (e) {
    console.error(e);
    error = true;
  }
  return error;
};

export const createStudyMaterialRecord = ({
  study_id,
  template_id,
  cb = false,
  cont = false,
} = {}) => {
  MatgenGlobal.promptSaveClicked = true;
  const res = MatgenGlobal.UI.promptModal({
    title: 'Name this material',
    label: 'Material name',
    action: async (name) => {
      MatgenGlobal.suppressLoaderStop = true;
      await saveMaterialRecord({
        study_id,
        name,
        id: UUID(),
        template_id,
        cb,
        cont,
        thumb: false,
      });
    },
  });
  window.setTimeout(() => {
    $(`
      <p style="max-width: 540px; text-align: center;">Enter a name for this material that will help you distinguish it from other materials within this folder.</p>
    `).insertBefore($('#prompt-form .row'));
  }, 250);
  return res;
};

export const loadEditorData = async ({
  template_id,
  material_id,
  page_id,
} = {}) => {
  if (material_id) {
    let material = await MatgenGlobal.Data.getMaterial(material_id);
    if (material && Array.isArray(material)) {
      material = material[0];
    }
    const template = await MatgenGlobal.Data.getTemplate(material.template_id);
    const study_id = material.user_folder_id;
    const opts = await authHeaderOpts();
    let study = await MatgenGlobal.Amplify.API.get(
      'authenticated',
      `/user-folders/${study_id}`,
      opts
    );
    if (study && Array.isArray(study)) {
      study = study[0];
    }
    MatgenGlobal.currentTemplate = template;
    //const tags = await setTranslatedTags();
    //console.error('TAGS:', tags);
    if (template.type === 'FILE') {
      const preview = await filePreview(template);
      return { template, preview };
    } else {
      const pages = await MatgenGlobal.Data.getPages(template.id);
      pages.sort((a, b) => a.number - b.number);
      return { study, material, template, pages, page_id };
    }
  } else {
    const material = await MatgenGlobal.Data.getMaterial(material_id);
    let study_id = MatgenGlobal.getQueryParam('study_id');
    if (!study_id) {
      study_id = material.user_folder_id;
    }
    const opts = await authHeaderOpts();
    let study;
    if (study_id) {
      study = await MatgenGlobal.Amplify.API.get(
        'authenticated',
        `/user-folders/${study_id}`,
        opts
      );
    }
    if (study && Array.isArray(study)) {
      study = study[0];
    }
    // /console.log('LOAD TEMPLATE', template_id);
    const template = await MatgenGlobal.Data.getTemplate(template_id);
    MatgenGlobal.currentTemplate = template;

    //const tags = await setTranslatedTags();
    //console.error('TAGS:', tags);

    if (template.type === 'FILE') {
      const preview = await filePreview(template);
      return { template, preview };
    } else {
      const pages = await MatgenGlobal.Data.getPages(template_id);
      pages.sort((a, b) => a.number - b.number);
      return { study, template, pages, page_id };
    }
  }
};
